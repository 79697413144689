import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                code: {
                    // name: 'firstname',
                    label: '',
                    name: 'code',
                    placeholder: 'Bijv. LF123456',
                    autocomplete: '',
                    tabindex: 1
                }
            },

            validationSchema: yup.object().shape({
                code: yup.string()
                    .label( 'code' )
                    .required()
                    .nullable()
                    /* eslint-disable-next-line */
                    .matches( /^([A-Z])([\da-z]){7}$/i )
            })
        };
    }
};
