import * as yup from 'yup';

/* eslint-disable */
function isValidIBANNumber( input )
{
    var CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
            digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}
function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}
/* eslint-enable */

let minAge = new Date();
minAge.setYear( minAge.getFullYear() - 18 );
minAge.setHours( 0 );
minAge.setMinutes( 0 );
minAge.setSeconds( 0 );

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                gender: [
                    {
                        id: 'gender-a',
                        label: 'De heer',
                        type: 'radio',
                        // value: 'male',
                        name: 'gender',
                        tabindex: 1
                    },
                    {
                        id: 'gender-b',
                        label: 'Mevrouw',
                        type: 'radio',
                        // value: 'female',
                        name: 'gender',
                        tabindex: 2
                    }
                ],
                firstname: {
                    // name: 'firstname',
                    label: 'Voornaam',
                    name: 'firstname',
                    placeholder: 'Bijv. Klaas',
                    autocomplete: 'given-name',
                    tabindex: 3
                },

                infixname: {
                    label: 'Tussenv.',
                    name: 'infixname',
                    placeholder: 'bijv. ten',
                    autocomplete: 'infix-name',
                    tabindex: 4
                },

                surname: {
                    label: 'Achternaam',
                    name: 'surname',
                    placeholder: 'bv. Klaassen',
                    autocomplete: 'family-name',
                    tabindex: 5
                },

                dayOfBirth: {
                    label: 'Dag',
                    name: 'dayOfBirth',
                    type: 'number',
                    placeholder: 'Dag',
                    autocomplete: 'bday-day',
                    min: 1,
                    max: 31,
                    tabindex: 6
                },

                monthOfBirth: {
                    label: 'Maand',
                    name: 'monthOfBirth',
                    type: 'number',
                    placeholder: 'Maand',
                    autocomplete: 'bday-month',
                    options: [
                        'januari',
                        'februari',
                        'maart',
                        'april',
                        'mei',
                        'juni',
                        'juli',
                        'augustus',
                        'september',
                        'oktober',
                        'november',
                        'december'
                    ],
                    tabindex: 7
                },

                yearOfBirth: {
                    label: 'Jaar',
                    name: 'yearOfBirth',
                    type: 'number',
                    placeholder: 'Jaar',
                    autocomplete: 'bday-year',
                    min: 1900,
                    max: new Date().getFullYear(),
                    tabindex: 8
                },

                zipcode: {
                    label: 'Postcode',
                    name: 'zipcode',
                    placeholder: 'bijv. 1234AB',
                    tabindex: 9
                },

                house_num: {
                    label: 'Huisnummer',
                    name: 'house_num',
                    type: 'number',
                    min: 0,
                    tabindex: 10
                },

                house_num_add: {
                    name: 'house_num_add',
                    label: 'Toevoeging',
                    tabindex: 11
                },

                iban: {
                    label: 'Iban nummer',
                    name: 'iban',
                    type: 'text',
                    placeholder: 'Bijv. NL42 ABNA 1234 5678 90',
                    tabindex: 11
                },

                telephone: {
                    label: 'Telefoonnummer',
                    name: 'telephone',
                    type: 'tel',
                    tabindex: 12
                },

                emailaddress: {
                    label: 'E-mailadres',
                    name: 'emailaddress',
                    placeholder: 'bijv. info@lotto.nl',
                    type: 'email',
                    tabindex: 13
                },

                kit: {
                    type: 'checkbox',
                    name: 'kit',
                    id: 'kit-checkbox',
                    tabindex: 14
                }
            },

            validationSchema: yup.object().shape({
                gender: yup.string()
                    .label( 'aanhef' )
                    .required(),
                firstname: yup.string()
                    .label( 'voornaam' )
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^([A-z\-])+$/ )
                    .min( 2 ),
                surname: yup.string()
                    .label( 'achternaam' )
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^([A-z\-])+$/ )
                    .min( 2 ),
                dayOfBirth: yup.number()
                    .nullable()
                    .label( 'dag' )
                    .required()
                    .min( 1 ),
                monthOfBirth: yup.number()
                    .nullable()
                    .label( 'maand' )
                    .required()
                    .min( 0 )
                    .max( 11 ),
                yearOfBirth: yup.number()
                    .nullable()
                    .label( 'jaar' )
                    .required()
                    .min( 1900, 'Geboortejaar is ongeldig' ),
                dateOfBirth: yup.date()
                    .label( 'geboortedatum' )
                    .required()
                    .max( minAge, 'Je moet minimaal 18 jaar zijn.' )
                    .test(
                        'date-of-birth',
                        'Dit is geen geldige geboortedatum',
                        value =>
                        {
                            if( isNaN( value ) ) // no real date
                            {
                                return false;
                            }

                            /* eslint-disable */
                            if( value.getDate() != this.entry.dayOfBirth ||
                                value.getMonth() != this.entry.monthOfBirth ||
                                value.getFullYear() != this.entry.yearOfBirth ) // mismatch in date input and date model
                            {
                                return false;
                            }
                            /* eslint-enable */

                            return true;
                        }
                    ),
                zipcode: yup.string()
                    .label( 'postcode' )
                    .required()
                    .matches( /^[1-9][0-9]{3} {0,1}[A-Za-z]{2}$/ ),
                house_num: yup.number()
                    .label( 'huisnummer' )
                    .nullable()
                    .required()
                    .min( 1 ),
                house_num_add: yup.string()
                    .label( 'toevoeging' )
                    /* eslint-disable-next-line */
                    .matches( /^([A-z0-9\-])+$/, { excludeEmptyString: true })
                    .max( 10 ),
                iban: yup.string()
                    .label( 'iban nummer' )
                    .required()
                    .matches( /^(NL[0-9]{2}[A-Z]{4}[0-9]{10})$/i )
                    .test( 'iban-test', 'Dit is geen geldige IBAN', value =>
                    {
                        let result = isValidIBANNumber( value );

                        /*
                         * Returns 1 if the IBAN is valid
                         * Returns FALSE if the IBAN's length is not as should be (for NL the IBAN Should be 18 chars long starting with NL )
                         * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
                        */

                        return ( result === 1 );
                    }),
                telephone: yup.string()
                    .label( 'telefoonnummer' )
                    .nullable()
                    /* eslint-disable-next-line */
                    .matches( /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/, { excludeEmptyString: true }),
                emailaddress: yup.string()
                    .label( 'e-mail' )
                    .required()
                    .email( 'Dit is geen valide mailadres' )
            })
        };
    }
};
