import * as yup from 'yup';

/* eslint-disable */
function isValidIBANNumber( input )
{
    var CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
            digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}
function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}
/* eslint-enable */

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                iban: {
                    label: 'Iban nummer',
                    name: 'iban',
                    type: 'text',
                    placeholder: 'Bijv. NL42 ABNA 1234 5678 90',
                    tabindex: 11
                },

                telephone: {
                    label: 'Telefoonnummer',
                    name: 'telephone',
                    type: 'tel',
                    tabindex: 12
                },

                emailaddress: {
                    label: 'E-mailadres',
                    name: 'emailaddress',
                    placeholder: 'bijv. info@lotto.nl',
                    type: 'email',
                    tabindex: 13
                },

                kit: {
                    type: 'checkbox',
                    name: 'kit',
                    id: 'kit-checkbox',
                    tabindex: 14
                }

            },

            validationSchema: yup.object().shape({
                iban: yup.string()
                    .label( 'iban nummer' )
                    .required()
                    .matches( /^(NL[0-9]{2}[A-Z]{4}[0-9]{10})$/i )
                    .test( 'iban-test', 'Dit is geen geldige IBAN', value =>
                    {
                        let result = isValidIBANNumber( value );

                        /*
                         * Returns 1 if the IBAN is valid
                         * Returns FALSE if the IBAN's length is not as should be (for NL the IBAN Should be 18 chars long starting with NL )
                         * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
                        */

                        return ( result === 1 );
                    }),
                telephone: yup.string()
                    .label( 'telefoonnummer' )
                    .nullable()
                    /* eslint-disable-next-line */
                    .matches( /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/, { excludeEmptyString: true }),
                emailaddress: yup.string()
                    .label( 'e-mail' )
                    .required()
                    .email( 'Dit is geen valide mailadres' )
            })
        };
    }
};
