import Proxy from './Proxy';

class AddressProxy extends Proxy
{
    constructor( parameters = {})
    {
        super( 'api/address', parameters );
    }
}

export default AddressProxy;
